<template>
  <div class="row-wrap custom-container">
    <FriendCreate @success="success" @cancel="cancel" />
  </div>
</template>

<script>
import FriendCreate from "@/components/好友相關/FriendCreate.vue";
export default {
  components: { FriendCreate },
  data() {
    return {};
  },
  created() {},
  methods: {
    success() {
      window.localStorage.setItem("friendState", "createNew");
      this.$router.push({ path: "/friendList" });
    },
    cancel() {
      this.$router.push({ path: "/friendList" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.row-wrap {
  @include align(center, flex-start);
}
.custom-container {
  padding: 0 24px;
}
</style>
